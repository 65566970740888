import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Flex, Heading, Link, Text } from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"

const styles = {
  root: {
    height: "auto",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mt: { base: "24px", md: "24px", lg: "32px" },
    mb: "32px",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
  },
  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "140px" },
  },
  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },
  button: {
    mt: "24px",
    height: "56px",
    width: "147px",
    padding: "8px 16px",
    bg: "#129459",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  link: {
    color: "#3399FF",
    _hover: {
      color: "#3399FF",
    },
  },
  whitespace: {
    pb: "24px",
  },
}

const navigateToPacuAndOrThroughput = () =>
  navigate("/learn-more-about-PACU-and-OR-throughput/")

const SectionTopChallenges = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Are Staff Frustration and Burnout Among Your Top Challenges?
        </Heading>
      </Flex>

      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/throughput/throughput-challenges-a.png"
            alt="Illustration three doctors against a yellow background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Text sx={styles.text}>
            Industry surveys indicate that staff shortages, burnout and
            frustration are the{" "}
            <Link
              sx={styles.link}
              href="https://www.beckershospitalreview.com/hospital-management-administration/the-no-1-problem-keeping-hospital-ceos-up-at-night.html"
              isExternal
            >
              most concerning issues for US hospitals.
            </Link>
            <Text sx={styles.whitespace}></Text>
            Bottlenecks in the PACU and cascading delays in the OR create
            significant frustration for your perioperative staff, especially
            when the delays result in unscheduled overtime.
            <Text sx={styles.whitespace}></Text> With the CareMeasurement
            Throughput application you can optimize schedules to greatly reduce
            and even eliminate the frustration and cost of unplanned extended
            hours in the OR and PACU.
          </Text>
        </Flex>
      </HeroLeft>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our CM Throughput application in action."
          sx={styles.button}
          onClick={navigateToPacuAndOrThroughput}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionTopChallenges
